.markdown {
  textarea {
    min-height: 120px !important;
    font-family: 'Eina 01';
    font-weight: normal;
    font-size: 1em;
  }

  [class~=button-type-underline] {
    display: none !important;
  }
}
