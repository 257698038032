.filter {
  padding-bottom: 10px;
  border-bottom: 1px solid #dee0e3;
  height: 50px;
  overflow: visible;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
}
