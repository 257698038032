.wrapper {
  margin-top: 8px;

  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px;
    background: #f4f5f9;
    border: 1px solid;
    border-color: #ddd;

    button:not(:last-child) {
      margin-right: 1rem;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .arrow {
      margin-left: auto;
      margin-right: 20px;
    }

    &::before {
      content: '';
      position: relative;
      min-width: 10px;
      min-height: 10px;
      border-radius: 50%;
      background-color: #e72076;
      margin-right: 16px;
      margin-top: 2px;
    }
  }

  .arrow {
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: all 300ms ease-in-out;
    transform: rotate(0deg);

    &.open {
      transform: rotate(180deg);
    }
  }

  .content {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    border: 1px solid;
    border-top: 0;
    border-color: #ddd;
    padding: 8px 16px 0 16px;

    &.open {
      opacity: 1;
    }
  }
}
