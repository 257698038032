.highlights {
  background-color: #fff;
  padding: 45px 25px 10px;
  line-height: 1.4em;
  font-weight: 300;
  color: #333;

  @media screen and (max-width: 767px) {
    padding: 25px 25px 1px;
  }

  @media print {
    padding: 20px 0 4px;
  }
}

.title {
  margin: 0;
  font-size: 23px;
  line-height: 1.4em;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    font-size: 21px;
  }

  @media print {
    margin-top: 0;
    font-size: 17px;
  }
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;

  p {
    font-size: 19px;
    line-height: 26px;
    margin: 8px 0 35px;

    @media screen and (max-width: 767px) {
      font-size: 17px;
      margin-bottom: 25px;
    }

    @media print {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }
}
