.people {
  padding: 45px 25px 22px;
  border-top: 1px solid #e8e8e8;

  @media screen and (max-width: 1024px) {
    padding-bottom: 27px;
  }

  @media screen and (max-width: 767px) {
    padding: 25px 25px 7px;
  }

  @media screen and (max-width: 678px) {
    padding-bottom: 13px;
  }

  @media print {
    padding: 20px 0;
    page-break-inside: avoid;
  }
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 23px;
  line-height: 1.4em;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    font-size: 21px;
    margin-bottom: 16px;
  }

  @media print {
    margin-top: 0;
    margin-bottom: 13px;
    font-size: 17px;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
