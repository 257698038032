.rating {
  display: flex;
  margin-bottom: 12px;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    min-width: 320px;

    &:nth-child(odd) {
      margin-right: 50px;
    }
  }

  @media screen and (max-width: 799px) {
    width: 100%;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  @media print {
    min-width: 48%;

    &:last-child {
      margin-bottom: 12px;
    }

    &:nth-child(odd) {
      margin-right: 20px;
    }
  }
}

.wrapper {
  max-width: 110px;

  @media screen and (max-width: 1024px) {
    max-width: 160px;
  }

  @media print, screen and (max-width: 799px) {
    max-width: inherit;
  }
}

.name {
  font-size: 15px;
  line-height: 28px;
}

.stars {
  display: flex;
  flex-wrap: wrap;
}

.star {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 24px;
  background-color: #d8d8d8;
  border-radius: 50%;
}

.selected {
  background-color: #e72076;
}
