.navigation {
  background-color: #fff;
  position: fixed;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 12px 8px -4px rgba(0, 0, 0, 0.04);
  display: flex;
  visibility: hidden;
  opacity: 0;

  @media (max-width: 1124px) {
    top: 67px;
  }
}

.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 300ms;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300s, opacity 300ms;
}

.container {
  max-width: 1198px;
  padding: 15px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-family: 'Mediator Serif Web';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 28px;
  width: 336px;

  @media screen and (max-width: 1099px) {
    font-size: 16px;
    line-height: 23px;
  }

  @media screen and (max-width: 1124px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  padding-left: 50px;
  justify-content: space-between;
  flex-grow: 1;

  @media screen and (max-width: 1124px) {
    padding: 0;
    width: 100%;
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
