.gap320 {
  width: 100%;
  height: 320px;
}

.gap32 {
  width: 100%;
  height: 32px;
}

.error {
  color: #e72076;

  &::before {
    font-family: 'fontAwesome', sans-serif;
    font-size: 24px;
    color: #e72076;
    content: '\f00d '; // xmark
    padding-right: 12px;
  }

  @media (max-width: 413px) {
    font-size: 16px;
  }
}

.successful {
  color: green;

  &::before {
    font-family: 'fontAwesome', sans-serif;
    font-size: 24px;
    color: green;
    content: '\f00c '; // checkmark
    padding-right: 12px;
  }

  @media (max-width: 413px) {
    font-size: 16px;
  }
}

.active {
  transition: box-shadow 0.3s;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);
  }
}

.buttonBase {
  display: inline-block;
  border: 1px solid #f07;
  border-radius: 3px;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 20px;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);
  }

  @media (max-width: 413px) {
    font-size: 16px;
    margin-top: 0;
  }

  &.compact {
    font-size: 16px;
    padding: 5px 12px;

    @media (max-width: 413px) {
      font-size: 14px;
    }
  }
}

.primaryButton {
  @extend .buttonBase;

  color: white;
  background-color: #f07;
  height: fit-content;

  &:disabled {
    background-color: dimgray;
    cursor: not-allowed;
    border-color: dimgray;
  }
}

.secondaryButton {
  @extend .buttonBase;

  background-color: white;
  color: #f07;

  &:disabled {
    color: dimgray;
    cursor: not-allowed;
    border-color: dimgray;
  }
}

.secondaryButton.disabled {
  color: dimgray;
  cursor: not-allowed;
}

.saveButtonContainer {
  button {
    margin-right: 1rem;
  }

  > * {
    display: inline-block;
  }
}

.heading {
  display: flex;
  align-items: flex-start;
  font-family: 'Mediator Serif Web';
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 48px;
}

.description {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
}

.input,
.textarea {
  width: 75%;
  border: 1px solid #dadada;
  border-radius: 3px;
  background-color: white;
  height: 36px;
  padding: 10px;
  font-family: 'Eina 01';
  font-weight: normal;
  font-size: 1em;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.textarea {
  height: auto;
}

.list {
  margin: 10px 0 0;
  padding-left: 27px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  li {
    font-size: 16px;
    line-height: 28px;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}

.tableCell {
  padding-right: 22px;

  @media (max-width: 413px) {
    min-width: 120px;
    padding-right: 8px;
  }
}

.tableCellLevel {
  padding-right: 22px;

  @media (max-width: 413px) {
    min-width: 120px;
    padding-right: 8px;
  }
}

.label {
  margin: 18px 0 14px;
  font-size: 22px;
  font-weight: 500;
  padding-left: 12px;
}

.width75 {
  width: 75%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.inputContainer {
  margin: 1rem 0;
}

.addButton {
  border: 1px solid #dadada;
  border-radius: 3px;
  background-color: white;
  width: 36px;
  height: 36px;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);
  }
}

.alignRight {
  display: flex;
  justify-content: flex-end;
}

.headingWithButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  & * {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: inherit;
  }
}

.tableContainer {
  width: 75%;
  margin: 1rem 0;

  @media (max-width: 413px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.textButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #f07;
  font-size: 16px;
  font-style: italic;
  text-decoration: none;
}

.label.required::after {
  color: #e32;
  content: ' *';
  display: inline;
}

.leftAlignedHeader {
  th {
    text-align: left;
  }
}

.alternateColors {
  tr:nth-child(even) {
    background-color: lightgray;
  }
}

.nowrap {
  white-space: nowrap;
}

.padding1 {
  padding: 1rem;
}
