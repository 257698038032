$brand: #e72076;
$white: #fff;
$buttonGray: #6b6b6b;
$inactiveGray: #6b6b6b;

$breakpoint-desktop: 1124px;

.container {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 120px;
  background-color: $white;
  box-shadow: 0 12px 8px -4px rgba(0, 0, 0, 0.04);
  margin-bottom: 25px;

  @media (max-width: $breakpoint-desktop) {
    height: 68px;
  }

  @media print {
    display: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  padding: 0 15px;

  @media (min-width: $breakpoint-desktop) {
    max-width: 1198px;
    margin: 0 auto;
  }
}

.logo {
  width: 229px;
  height: 34px;

  @media screen and (max-width: $breakpoint-desktop) {
    width: 161px;
    height: 24px;
  }
}

.logoDesktop {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}

.navs {
  display: flex;
  flex-wrap: wrap;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-left: 80px;
  padding: 0;
  list-style: none;

  &:first-of-type {
    margin-left: 0;
  }

  @media (max-width: $breakpoint-desktop) {
    margin-left: 60px;
  }
}

.username {
  position: relative;
  font-size: 18px;
  line-height: 120px;
  color: $inactiveGray;

  &::after {
    content: '|';
    margin: 0 8px;
  }

  @media (max-width: $breakpoint-desktop) {
    line-height: 50px;
    font-size: 15px;
  }
}

.item {
  position: relative;
  height: 120px;
  display: flex;
  align-items: center;

  & + & {
    margin-left: 40px;

    @media (max-width: $breakpoint-desktop) {
      margin-left: 40px;
    }
  }
}

.navCollapsible {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: $white;
  overflow: hidden;
  transition: height 0.25s ease;
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.15);

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .item {
    margin: auto;
    width: 100%;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 6px;
    }
  }

  .active.link {
    color: $brand;
  }

  .link {
    &::after {
      height: 0;
    }
  }

  .login {
    &:first-child {
      padding-top: 0;
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    text-align: right;
    padding-right: 17px;
    margin-top: 6px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    .item {
      width: inherit;
      margin: 0;
    }
  }
}

.navCollapsible.onlyLoginLink {
  height: 45px;
}

.navCollapsible.collapsed {
  height: 0;
}

.link {
  display: block;
  width: 100%;
  color: #212d3b;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    color: $brand;
  }

  @media (max-width: $breakpoint-desktop) {
    font-size: 12px;
  }
}

.usernamelink {
  color: #212d3b;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;

  &:hover {
    color: $brand;
  }

  @media (max-width: $breakpoint-desktop) {
    font-size: 12px;
  }
}

.active {
  color: $brand;
}

.navbarToggle {
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;

  .iconBar {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background: $buttonGray;
    position: relative;

    &:nth-child(2) {
      top: -5px;
    }

    &:nth-child(3) {
      top: 0;
    }

    &:nth-child(4) {
      top: 5px;
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    display: none;
  }

  &:hover,
  &:focus {
    outline: 0;

    .iconBar {
      background-color: $brand;
    }
  }
}

.navbarToggle.isActive {
  .iconBar {
    &:nth-child(2) {
      top: 1px;
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      top: -1px;
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      display: none;
    }
  }
}
