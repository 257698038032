.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.orgImage {
  width: 100%;
  height: auto;
}
