.card {
  position: relative;
  width: 32%;
  height: 103px;
  margin-right: 2%;
  margin-bottom: 23px;
  overflow: visible;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media screen and (max-width: 1024px) {
    width: 49%;
    margin-right: 2%;
    margin-bottom: 18px;

    &:nth-of-type(3n) {
      margin-right: 2%;
    }

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 678px) {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 12px;
  }

  @media print {
    width: 49%;
    margin-right: 2%;
    margin-bottom: 23px;

    &:nth-of-type(3n) {
      margin-right: 2%;
    }

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 3px;
  text-decoration: none;
}

.active {
  transition: box-shadow 0.3s;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);
  }
}

.inactive {
  cursor: default;
  filter: grayscale(100%);
  background-color: #b4b4b4;
}

.personImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 78px;
  margin: 0;
  margin-right: 10px;
  overflow: hidden;
}

.projectImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 78px;
  margin: 0;
  margin-right: 12px;
  overflow: hidden;

  @media (max-width: 413px) {
    width: 98px;
    margin-right: 10px;
  }
}

.personImageSrc {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectImageSrc {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.personContent {
  width: calc(100% - 88px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.projectContent {
  width: calc(100% - 130px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 413px) {
    width: calc(100% - 110px);
  }
}

.personHeader {
  border-left: 3px solid #f07;
}

.projectHeader {
  border-left: 3px solid #f07;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title {
  margin: 0;
  padding-left: 5px;
  font-size: 17px;
  line-height: 18px;
  font-weight: 600;
  color: #212d3b;

  @media (max-width: 413px) {
    font-size: 16px;
  }
}

.subtitle {
  margin: 0;
  padding: 5px 0 0 5px;
  font-size: 12px;
  color: #333;
}

.firstLine {
  margin: 0;
  padding: 3px 0 0 5px;
  font-size: 14px;
  color: #212d3b;
  font-weight: 300;

  @media (max-width: 413px) {
    font-size: 13px;
  }
}

.secondLine {
  max-height: 40px;
  margin: auto 0 0 0;
  font-size: 15px;
  color: #212d3b;

  @media (max-width: 413px) {
    font-size: 15px;
  }
}

.description {
  max-height: 38px;
  margin: auto 0 0 8px;
  font-size: 15px;
  color: #212d3b;
  overflow: hidden;
  line-height: 20px;

  @media (max-width: 413px) {
    font-size: 14px;
  }
}

.ribbon {
  position: relative;
  padding: 4px 8px;
  background-color: #f07;
  color: #fff;
  font-size: 11px;
  pointer-events: none;
  font-weight: 500;
  height: 20px;
  margin-left: 12px;
}
