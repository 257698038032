.skillGroupTitle {
  margin: 18px 0 14px;
  font-size: 22px;
  font-weight: 500;
  padding-left: 12px;

  @media (max-width: 413px) {
    font-size: 16px;
  }
}

.skillGroupLevel {
  @media (max-width: 413px) {
    font-size: 14px;
  }
}

.tableContainer {
  width: 75%;
  margin: 22px;
  background-color: #f9f8f6;
  padding: 22px;

  @media (max-width: 413px) {
    width: 100%;
    margin: 22px 0;
    padding: 12px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 22px 0;
  }
}
