.error {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
}

.title {
  font-size: 52px;
  color: #9f9f9f;
}

.back {
  margin-top: 35px;
  font-size: 24px;
  color: #9f9f9f;

  a {
    color: #9f9f9f;

    &:hover {
      text-decoration: none;
    }
  }
}
