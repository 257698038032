.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 45px 25px;
  background-color: #f8f8f8;
  color: #333;
  border-top: 1px solid #e8e8e8;

  @media screen and (max-width: 767px) {
    padding: 25px;
  }

  @media print {
    padding: 20px 25px;
    border-bottom: 1px solid #e8e8e8;
  }
}

.reference {
  width: 100%;
  max-width: 900px;
}

.text {
  font-size: 23px;
  line-height: 36px;
  text-align: center;

  p {
    margin: 0 0 16px;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 25px;

    p {
      &:last-of-type {
        margin-bottom: 25px;
      }
    }
  }

  @media print {
    font-size: 16px;
    line-height: 22px;

    p {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 14px;
      }
    }
  }
}

.person {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.photo {
  width: 65px;
  height: 65px;
  border-radius: 50%;

  @media print, screen and (max-width: 767px) {
    width: 55px;
    height: 55px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.name {
  font-size: 21px;
  line-height: 1.4em;
  font-weight: 500;
  color: #f07;
  margin-bottom: 5px;

  @media print {
    font-size: 18px;
    margin-bottom: 2px;
  }
}

.position {
  a {
    color: #f07;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  @media print {
    font-size: 15px;
  }
}
