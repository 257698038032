.header {
  position: relative;
  height: calc(100vh - 50px);
  min-height: 550px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 0 15px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  a,
  span {
    color: #fff;
  }

  @media print {
    height: 250px;
    min-height: 0;
  }
}

.wrapper {
  position: relative;
  max-width: 1170px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.children {
  position: absolute;
  left: 0;
  top: 18px;

  @media screen and (max-width: 480px) {
    top: 40px;
  }
}

.image {
  margin-bottom: 40px;
  max-width: 20%;

  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
    max-width: 30%;
  }

  @media print {
    margin-bottom: 20px;
  }
}

.title {
  font-size: 47px;
  line-height: 1.2em;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0 15px;

  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }

  @media screen and (max-width: 767px) {
    font-size: 28px;
  }

  @media print {
    font-size: 25px;
    margin-bottom: 10px;
  }
}

.subtitle {
  font-size: 25px;
  line-height: 1.4em;
  font-weight: 300;
  color: #fff;
  margin: 30px 0 0;
  padding: 0 100px;

  p {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    margin-top: 16px;
    padding: 0 50px;
  }

  @media print {
    font-size: 18px;
    margin-top: 0;
  }
}

.info {
  position: absolute;
  right: 0;
  top: 25px;
  font-size: 13px;

  strong {
    font-weight: 500;
  }

  p {
    margin: 8px 0;
  }

  @media screen and (max-width: 480px) {
    right: 0;
    top: 10px;
    text-align: left;
    line-height: 17px;
  }

  @media print {
    p {
      margin: 0;

      &:first-of-type {
        margin-bottom: 2px;
      }
    }
  }
}
