.section {
  background-color: #fff;
  padding-bottom: 80px;

  &:first-of-type {
    padding-top: 64px;

    @media screen and (max-width: 1024px) {
      padding-top: 80px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 56px;
    }

    @media print {
      border-top: 1px solid #d5d5d5;
    }
  }

  &:last-of-type {
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }
  }

  & + & {
    border-top: 1px solid #d5d5d5;
    padding-top: 80px;

    @media print, screen and (max-width: 767px) {
      padding-top: 56px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 56px;
  }

  @media print {
    padding-bottom: 56px;
    display: block;
    page-break-before: avoid;

    &:first-of-type {
      padding-top: 56px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.sidebar {
  background-color: transparent;
  padding: 40px 32px 0;

  &:first-of-type {
    padding-top: 32px;

    @media screen and (max-width: 767px) {
      padding-top: 22px;
    }
  }

  &:last-of-type {
    padding-bottom: 32px;

    @media screen and (max-width: 767px) {
      padding-bottom: 22px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 22px 22px 0;
  }

  @media print {
    padding: 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:nth-of-type(2) {
      padding-top: 28px;
    }

    &:nth-of-type(3) {
      padding-top: 28px;
    }

    &:last-of-type {
      padding-bottom: 44px;
    }
  }
}

.title {
  font-family: 'Mediator Serif Web';
  font-size: 42px;
  font-weight: bold;
  letter-spacing: -0.88px;
  line-height: 64px;
  margin: 0;

  @media screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 45px;
  }

  @media print {
    font-size: 38px;
    line-height: 55px;
    display: block;
    page-break-after: avoid;
  }
}

.sidebarTitle {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.35px;
  margin-bottom: 24px;
}
