.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  opacity: 1;
  transition: all 0.5s;
  overflow: hidden;
}

.title {
  width: 100%;
  margin: 18px 0 14px 0;
  font-size: 22px;
  font-weight: 500;
  padding-left: 12px;

  @media (max-width: 678px) {
    font-size: 20px;
  }

  @media print {
    margin-top: 0;
  }
}
