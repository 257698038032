.content {
  display: flex;
  flex-direction: column;
}

.info {
  margin: 0 0 16px;
}

.item {
  margin-bottom: 24px;
}

.webOnly {
  @media print {
    display: none;
  }
}

.dt {
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-transform: uppercase;
  color: #888;
  margin-bottom: 10px;
}

.dd {
  font-family: 'Mediator Serif Web';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin: 0;
}

.dd a {
  color: #212d3b;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.bio {
  p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }

  p + p {
    margin-top: 8px;
  }

  a {
    color: #212d3b;
    font-weight: 600;
    font-size: 15px;

    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 15px;
  }

  @media print {
    margin-bottom: 12px;
  }
}
