.dropdownHolder {
  display: flex;
  flex-direction: column;
  align-items: top;
  position: relative;
}

.splitButtonHolder {
  display: flex;
  align-items: left;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #e72076;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;
  border: none;

  &:hover {
    background-color: #d81c6d;
  }
}

.splitButtonLeft {
  width: 180px;
  border-right: 1px solid #f37baf;
}

.splitButtonRight {
  width: 40px;

  @media screen and (max-width: 767px) {
    width: 30px;
  }
}

.headerbutton {
  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-top: 40px;
  }

  @media screen and (max-width: 767px) {
    font-size: 13px;
    height: 50px;
  }

  @media print {
    display: none;
  }
}

.profilenavbutton {
  &:first-child {
    @media screen and (min-width: 767px) {
      margin-left: 40px;
    }
  }
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  top: 44px;
  padding: 0;
  opacity: 0;
  transition: opacity 300ms;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.dropdownButton {
  color: #fff;
  font-weight: 600;
  line-height: 28px;
  height: 30px;
  width: 220px;
  border: none;
  border-top: 1px solid #fff;
  background-color: #e72076;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #d81c6d;
  }
}
