.navigationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.helpIcon {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: -5px;

  @media (max-width: 413px) {
    margin-top: -30px;
  }

  svg {
    transform: scale(0.7);
  }
}

.tips li {
  margin-bottom: 1rem;
}
