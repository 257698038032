.submenu {
  position: sticky;
  top: 120px;
  margin-top: -25px; // This is due to main header 25px margin bottom
  width: 100%;
  z-index: 1;

  @media print, screen and (max-width: 1124px) {
    top: 67px;
  }

  @media (max-width: 479px) {
    margin-top: -50px;
  }
}

.submenuContent {
  padding: 25px 0;
  width: 100%;
  background-color: white;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;

  @media print, screen and (max-width: 1124px) {
    flex-direction: column;
    justify-content: space-between;
  }
}

.expandArrow {
  position: absolute;
  right: 0;
  top: 20px;
  display: none;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  @media print, screen and (max-width: 1124px) {
    display: flex;
  }
}

svg {
  transition: transform 0.5s;
}

.item {
  border-bottom: 2px solid transparent;
  font-size: 11px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #888;
  text-decoration: none;
  padding: 8px 8px 6px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: 479px) {
    border-bottom: none;
  }

  @media print, screen and (max-width: 1124px) {
    width: fit-content;
  }

  &:hover {
    border-bottom: 2px solid #e72076;
    color: #e72076;

    @media (max-width: 479px) {
      border-bottom: none;
    }
  }

  @media (max-width: 479px) {
    font-size: 9px;
    letter-spacing: 0.8px;
    line-height: 16px;
  }

  & + & {
    margin-left: 40px;

    @media (max-width: 1124px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.isActive {
  border-bottom: 2px solid #e72076;
  color: #e72076;

  @media (max-width: 479px) {
    border-bottom: none;
  }
}
