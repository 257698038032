.wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.5em;

  @media (max-width: 767px) {
    display: block;

    input {
      width: 100%;
    }
  }

  .container {
    input {
      border: 1px solid #dadada;
      border-radius: 3px;
      background-color: white;
      height: 36px;
      padding: 10px;
      font-family: 'Eina 01';
      font-weight: normal;
      font-size: 1em;

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);
      }
    }

    div {
      font-size: 14px;
      font-family: 'Eina 01';
      font-weight: normal;
    }

    [class~=react-datepicker] {
      box-shadow: 0 8px 16px rgba(45, 57, 144, 0.1);
      border-radius: 16px;
      border: none;
    }

    [class~=react-datepicker__month-text] {
      width: 96px;
      height: 1.5rem;
    }

    [class~=react-datepicker__year-wrapper] {
      max-width: 220px;
    }

    [class~=react-datepicker__month-text--keyboard-selected] {
      background-color: #2579ba;

      &:hover {
        background-color: #2579ba;
      }
    }
  }
}
