.wrapper {
  position: fixed;
  z-index: 1001;
  inset: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  z-index: 1000;
  inset: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog {
  z-index: 1002;
  background-color: white;
  border: 1px solid;
  border-color: #ddd;
  max-height: calc(100% - 64px);
  max-width: 600px;
  margin: 0.5rem;
}

.content {
  padding: 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid;
  border-color: #ddd;
  background: #f4f5f9;

  h2 {
    margin: 0;
  }
}

.closeIcon {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 0.5rem;

  svg {
    transform: scale(0.7);
  }
}
