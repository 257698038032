.contribution {
  width: 100%;

  @media print {
    display: block;
    margin-left: 28px;
  }
}

.content {
  margin: 24px 0 0 0;
  display: block;
}

.name {
  width: 100%;
  display: block;

  @media print {
    page-break-inside: avoid;
  }
}

.title {
  margin: 0;
  font-family: 'Mediator Serif Web';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 28px;
}

.description {
  margin: 8px 0 0;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
  }

  p + p {
    margin-top: 8px;
  }

  a {
    color: #212d3b;
    font-weight: 600;
    font-size: 15px;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    margin: 10px 0 0;
    padding-left: 27px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    li {
      font-size: 16px;
      line-height: 28px;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  h3 {
    font-size: 16px;
  }

  @media print {
    display: block;
    page-break-inside: avoid;

    p,
    ul > li {
      padding-right: 12px;
    }
  }
}
