.education {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 64px;

  @media screen and (max-width: 767px) {
    padding-top: 45px;
  }

  @media print {
    padding-top: 48px;
    display: block;
  }
}

.name {
  width: 100%;
  display: block;

  @media print {
    page-break-inside: avoid;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  font-family: 'Mediator Serif Web';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 32px;
  margin: 0 0 0 -28px;

  &::before {
    content: '';
    position: relative;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    background-color: #e72076;
    margin-right: 16px;
    margin-top: 10px;
  }

  @media print {
    margin: 0;
    padding-right: 12px;
  }
}

.detail {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-transform: uppercase;
  color: #e72076;
  margin-top: 8px;

  @media print {
    margin-left: 28px;
  }
}

.fieldOfStudy {
  &::after {
    content: '|';
    position: relative;
    margin: 0 7px;
  }
}

.description {
  margin-top: 24px;
  font-size: 16px;
  line-height: 28px;

  p {
    margin: 0;

    @media print {
      display: block;
      page-break-inside: avoid;
    }
  }

  p + p {
    margin-top: 8px;
  }

  ul {
    margin: 10px 0 0;
    padding-left: 27px;

    li {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  @media print {
    margin-left: 28px;
    padding-right: 12px;
  }
}
