.experience {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 64px;

  @media (max-width: 767px) {
    padding-top: 45px;
  }

  @media print {
    padding-top: 48px;
    display: block;
  }
}

.content {
  width: 100%;
  display: block;
}

.name {
  width: 100%;
  display: block;

  @media print {
    page-break-inside: avoid;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  font-family: 'Mediator Serif Web';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.8px;
  line-height: 48px;
  margin: 0 0 0 -28px;

  &::before {
    content: '';
    position: relative;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    background-color: #e72076;
    margin-right: 16px;
    margin-top: 18px;

    @media (max-width: 767px) {
      margin-top: 13px;
    }
  }

  a {
    color: #212d3b;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 38px;
  }

  @media print {
    margin: 0;
    padding-right: 12px;
  }
}

.detail {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  color: #e72076;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 3px;

  @media print {
    margin-left: 28px;
  }
}

.position {
  &::after {
    content: '|';
    position: relative;
    margin: 0 5px;
  }
}

.date {
  margin: 0;

  @media print {
    display: block;
    page-break-before: avoid;
  }
}

.description {
  margin-top: 24px;
  font-size: 16px;
  line-height: 28px;

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    a {
      color: #212d3b;
      font-weight: 600;
      font-size: 15px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  ul {
    padding-left: 27px;
    margin: 0;

    li:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }

  @media print {
    display: block;
    page-break-inside: avoid;
    margin-left: 28px;
    padding-right: 12px;
  }
}

.link {
  width: 100%;
  margin-top: 24px;

  @media print {
    margin-left: 28px;
  }
}

.linkUrl {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #e72076;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 413px) {
    display: block;
    margin-left: 0;
    margin-top: 8px;
  }
}
