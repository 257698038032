.skill {
  display: flex;
  flex-direction: column;
  width: 100%;

  & + & {
    padding-top: 40px;

    @media print {
      padding-top: 30px;
    }
  }

  @media print {
    display: block;
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
  }
}

.title {
  font-family: 'Mediator Serif Web';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin: 0 0 24px;
}

.content {
  @media print {
    width: 100%;
    display: block;
    page-break-inside: avoid;
  }
}

.ratings {
  @media print, screen and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.description {
  margin: 5px 0;

  @media print {
    font-size: 15px;
    line-height: 28px;
  }
}
