.extras {
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  padding: 45px 25px;
  background-color: #fff;
  font-size: 19px;
  line-height: 1.4em;
  font-weight: 300;
  color: #333;
  border-top: 1px solid #e8e8e8;

  @media screen and (max-width: 767px) {
    padding: 25px;
  }

  @media print {
    padding: 20px 0;
    font-size: 14px;
  }
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;

  p {
    margin: 8px 0 0;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 25px;
    }

    @media print {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.title {
  margin: 0;
  font-size: 23px;
  line-height: 1.4em;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    font-size: 21px;
  }

  @media print {
    font-size: 18px;
  }
}
