@import './common.module';

.enhanceButton {
  @extend .primaryButton;

  margin-top: 16px;
}

.buttonGroup {
  margin-top: 16px;
}

.buttonGroup > button {
  margin: 0 8px;
}

.buttonGroup > button:first-child {
  margin-left: 0;
}

.buttonGroup > button:last-child {
  margin-right: 0;
}

.title {
  display: inline-block; // collapsing margins...
}

.aiTextWrapper {
  border: 1px solid #ddd;
  border-top-width: 0;
  padding: 0 16px 16px 16px;
}

.suggestedText {
  @extend .description;

  font-family: 'Eina 01';
  white-space: pre-wrap;
}
