.wrapper {
  display: flex;
  position: relative;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media print {
    display: block;
  }
}

.leftSide {
  min-width: 336px;
  max-width: 336px;
  background-color: #f9f8f6;
  height: 100%;

  @media screen and (max-width: 1024px) {
    min-width: inherit;
    max-width: inherit;
    height: inherit;
  }

  @media print {
    background-color: #fff;
    display: block;
    page-break-after: avoid;
    height: inherit;
    min-width: inherit;
    max-width: inherit;
  }
}

.rightSide {
  background-color: #fff;
  margin-left: 76px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media print {
    width: 100%;
    margin-left: 0;
  }
}

.banner {
  display: none;

  img {
    width: 187px;
    height: 25px;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #6d6d6d;
  }

  @media print {
    display: flex;
    align-items: center;
    margin-top: -16px;
  }
}

.motto {
  font-size: 16px;
  color: #6d6d6d;
}
