.wrapper {
  max-width: 1198px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;

  @media (max-width: 413px) {
    padding: 25px 8px 0;
  }
}
