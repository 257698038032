.breadcrumbs {
  margin: 55px 0 64px;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 1.5px;
  font-weight: 600;
  text-transform: uppercase;

  a {
    color: #212d3b;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 55px;
  }

  @media print {
    display: none;
  }
}

.breadcrumb {
  &::before {
    content: '>';
    margin: 0 5px;
  }

  &:first-child {
    &::before {
      content: '';
      margin: 0;
    }
  }
}
