.header {
  position: relative;
  background-color: #fff;
  margin-bottom: 120px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    margin-bottom: 90px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 70px;
  }

  @media print {
    margin-top: 100px;
    margin-bottom: 70px;
  }
}

.photo {
  margin-right: 48px;

  @media screen and (max-width: 1024px) {
    margin-right: 36px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.photoSrc {
  width: 176px;
  height: 168px;
  object-fit: cover;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.title {
  font-family: 'Mediator Serif Web';
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 48px;

  @media print, screen and (max-width: 767px) {
    padding-right: 0;
  }
}

.name {
  font-size: 48px;
  letter-spacing: -0.9px;
  line-height: 80px;

  @media screen and (max-width: 1024px) {
    font-size: 43px;
    line-height: 49px;
  }

  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media print {
    font-size: 41px;
    line-height: 67px;
  }
}

.position {
  font-size: 32px;
  letter-spacing: -0.56px;
  line-height: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 36px;
    margin-top: 12px;
  }

  @media screen and (max-width: 767px) {
    font-size: 21px;
    line-height: 28px;
  }

  @media print {
    font-size: 30px;
    line-height: 40px;
    padding-right: 24px;
  }
}

.cvButton {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0.5rem 1.25rem;
  color: hsl(334deg, 21%, 32%);
  font-weight: 700;
  text-decoration: none;
  justify-content: center;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #fcecf2;
    color: hsl(334deg, 81%, 52%);
  }

  @media print {
    display: none;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
