.accomplishment {
  word-break: break-word;

  @media print {
    display: block;
    page-break-after: avoid;
    margin-bottom: 12px;
  }

  &:last-of-type {
    .description {
      margin-bottom: 0;
    }
  }
}

.title {
  margin: 0 0 12px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-transform: uppercase;
  color: #888;
}

.description {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 24px;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 8px;
  }

  ul {
    margin: 0;
    padding-left: 27px;

    li {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  ol {
    margin: 0;
    padding-left: 17px;

    li {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  a {
    color: #212d3b;
    font-weight: 600;
    font-size: 15px;

    &:hover {
      text-decoration: none;
    }
  }

  strong {
    font-size: 15px;
  }

  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }

  @media print {
    p,
    ul > li,
    ol > li {
      padding-right: 12px;
    }
  }
}
