.login {
  width: 50%;
  margin: 175px auto 0 auto;
  text-align: center;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: 125px;
  }
}

.button {
  display: inline-block;
  background-color: #f07;
  border: 0;
  border-radius: 3px;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: darken(#f07, 10%);
  }
}
