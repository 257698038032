.container {
  flex-direction: column;
  position: fixed;
  background-color: white;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 0.3rem;
  z-index: 5; /* Higher than the z-index of the background */
  box-shadow: 0 0 10px 0 rgba(180, 180, 180, 0.5);

  @media (max-width: 413px) {
    width: 300px;
    margin-right: 10px;
  }
}

.confirmationText {
  display: flex;
  color: black;
  margin: 0.5rem 0 1.5rem 0.5rem;
  text-align: center;
  line-height: 1.5rem;
  font-size: 18px;

  @media (max-width: 413px) {
    font-size: 16px;
  }
}

.buttonContainer {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
}

.cancelButton {
  background-color: #999;
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
  opacity: 0.55;
  overflow: hidden;
  z-index: 2;
}

.titleContainer {
  background-color: #fde8f1;
  font-weight: 600;
  font-size: 22px;
  height: 50px;
  padding: 12px 12px 0 12px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 413px) {
    font-size: 18px;
  }

  .title {
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 413px) {
      max-width: 250px;
    }
  }

  .closeButton {
    margin: -0.25rem -0.25rem;
    font-size: 26px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 413px) {
      font-size: 22px;
    }
  }
}

.bodyContainer {
  padding: 1rem;
}
