.submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;

  @media print, screen and (max-width: 767px) {
    display: none;
  }
}

.submenuSticky {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  margin-left: auto;
  padding-right: 121px;
  min-width: 556px;

  @media print, screen and (max-width: 767px) {
    margin-left: 0;
    padding-right: 0;
    min-width: inherit;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-evenly;
  }
}

.item {
  border-bottom: 2px solid transparent;
  font-size: 11px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #888;
  text-decoration: none;
  padding: 8px 8px 6px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: 479px) {
    border-bottom: none;
  }

  &:hover {
    border-bottom: 2px solid #e72076;
    color: #e72076;

    @media (max-width: 479px) {
      border-bottom: none;
    }
  }

  @media (max-width: 479px) {
    font-size: 9px;
    letter-spacing: 0.8px;
    line-height: 16px;
  }

  & + & {
    margin-left: 40px;

    @media (max-width: 479px) {
      margin-left: 15px;
    }
  }
}

.isActive {
  border-bottom: 2px solid #e72076;
  color: #e72076;

  @media (max-width: 479px) {
    border-bottom: none;
  }
}
