.intro {
  display: flex;
  align-items: center;
  padding: 45px 25px;
  background-color: #f2f2f2;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #333;
  border-bottom: 1px solid #e8e8e8;

  @media screen and (max-width: 767px) {
    padding: 25px;
    font-size: 21px;
    line-height: 30px;
  }

  @media print {
    padding: 20px 25px;
    background-color: #fff;
    font-size: 17px;
    line-height: 1.4em;
  }
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;

  p {
    margin: 0;
  }
}
