* {
  box-sizing: border-box;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

body {
  font-family: 'Eina 01';
  font-weight: normal;
  font-size: 1em;
  padding-bottom: 60px;
  background-color: #fff;
  color: #212d3b;

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    padding: 0;
    margin: 0;
  }
}

@font-face {
  font-family: 'Eina 01';
  src: url('../fonts/Eina01-Regular.woff2') format('woff2'), url('../fonts/Eina01-Regular.woff') format('woff'),
    url('../fonts/Eina01-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 01';
  src: url('../fonts/Eina01-SemiBold.woff2') format('woff2'), url('../fonts/Eina01-SemiBold.woff') format('woff'),
    url('../fonts/Eina01-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Mediator Serif Web';
  src: url('../fonts/MediatorSerifWeb-Bold.woff2') format('woff2'),
    url('../fonts/MediatorSerifWeb-Bold.woff') format('woff'),
    url('../fonts/MediatorSerifWeb-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
