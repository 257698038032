.loading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #e9ebee;
}

.loader {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 8px solid rgba(255, 255, 255, 0.5);
  border-left-color: #fff;
  transform: translateZ(0);
  animation: loader 1s infinite linear;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
