.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.selectButton {
  margin: 4px;
  padding: 0 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 30px;
  color: #6b6b6b;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #f07;
  }
}

.selectButtonActive {
  color: #f07;
}

.optionsBox {
  width: 250px;
  height: 250px;
  overflow-y: auto;
  background-color: white;
  z-index: 1;
  border: 1px solid #dee0e3;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
}

.option {
  margin: 4px;
  min-height: 25px;
  padding: 0 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 30px;
  color: #6b6b6b;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    color: #f07;
  }
}

.active {
  background-color: #fff;

  &::after {
    font-family: 'fontAwesome', sans-serif;
    color: #f07;
    content: '\f00c '; // checkmark
  }
}
