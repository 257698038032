.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 25px;
  background-color: #f2f2f2;

  @media print {
    padding: 20px 25px;
    margin-bottom: 16px;
  }
}

.screen {
  max-width: 100%;
  max-height: 100%;

  @media print {
    max-width: 50%;
  }
}
